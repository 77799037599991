/* eslint-disable no-nested-ternary */
import {
  ArticleJsonLd,
  BreadcrumbJsonLd,
  NextSeo,
  SocialProfileJsonLd,
  FAQPageJsonLd,
} from 'next-seo';
import NextHead from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';
import { escapeString } from 'tf-post-parser';
import xss from 'xss';
import { FACEBOOK_APP_ID, ROOTURL } from '../../config';
import imageCrop from 'helpers/imageCrop';

const sanitize = input => {
  if (!input) return xss(input);
  const jsonString = JSON.stringify(input);
  return xss(jsonString.slice(1, -1));
};

const Head = props => {
  const {
    justTitle,
    shorttitle,
    noIndex,
    noFollow,
    scripts,
    includeMapbox,
    breadcrumbs,
    language,
    feedUrl,
    isAmp,
    post,
    profile,
    image,
    noImage,
    faq,
    isNotFree,
  } = props;
  const { community, tags, createdAt, updatedAt, thumbnail } = post;
  let seo = {};
  if (props.seo) seo = props.seo;
  const { metaDescription, isIndex } = seo;
  let { canonicalUrl } = seo;
  if (!canonicalUrl) canonicalUrl = seo.hostingUrl;
  let { author } = post;
  const description =
    sanitize(metaDescription) ||
    `${escapeString(`${xss(props.description)} `)}`;
  if (profile.author) {
    author = profile.author;
  }
  let title = shorttitle
    ? `${escapeString(`${xss(shorttitle)} `)} - TravelFeed`
    : `${escapeString(
        `${xss(props.title)} ` || 'TravelFeed ',
      )} - TravelFeed: Travel, Write, Earn`;
  if (justTitle) title = sanitize(justTitle);
  let socialProfiles;
  const openGraph = {
    title: sanitize((seo.facebook && seo.facebook.title) || title),
    description: sanitize(
      (seo.facebook && seo.facebook.description) || description,
    ),
    url: sanitize(canonicalUrl),
    site_name: 'TravelFeed',
    type: 'website',
  };
  const cleanTags = [];
  if (tags)
    tags.forEach(tag => {
      cleanTags.push(sanitize(tag));
    });
  if (post.author) {
    openGraph.type = 'article';
    openGraph.article = {
      section:
        community && community.title !== 'TravelFeed'
          ? sanitize(community.title)
          : 'Travel',
      tags: tags ? cleanTags : ['travel'],
      publishedTime: createdAt,
      modifiedTime: updatedAt,
      authors: [`https://travelfeed.com/@${author.username}`],
    };
    if (seo.facebook && seo.facebook.thumbnail)
      openGraph.images = [
        {
          url: imageCrop(seo.facebook.thumbnail, 1200, 630),
          width: 1200,
          height: 630,
          alt: title,
        },
      ];
    else
      openGraph.images = image
        ? [
            {
              url: image,
              width: 1200,
              height: 630,
              alt: title,
            },
          ]
        : thumbnail
        ? [
            {
              url: imageCrop(thumbnail, 1200, 630),
              width: 1200,
              height: 630,
              alt: title,
            },
          ]
        : undefined;
  }
  if (profile.author) {
    openGraph.type = 'profile';
    openGraph.profile = {
      author: sanitize(author.username),
      firstName: sanitize(author.displayName),
    };
    openGraph.images = author.avatar
      ? [
          {
            url: imageCrop(author.avatar, 850, 650),
            width: 850,
            height: 650,
            alt: sanitize(author.displayName),
          },
        ]
      : undefined;
    const { facebook, twitter, instagram, pinterest, youtube } = profile;
    if (facebook || twitter || instagram || pinterest || youtube) {
      socialProfiles = [];
      if (facebook)
        socialProfiles.push(`https://facebook.com/${sanitize(facebook)}`);
      if (twitter)
        socialProfiles.push(`https://twitter.com/${sanitize(twitter)}`);
      if (instagram)
        socialProfiles.push(`https://instagram.com/${sanitize(instagram)}`);
      if (pinterest)
        socialProfiles.push(`https://pinterest.com/${sanitize(pinterest)}`);
      if (youtube)
        socialProfiles.push(`https://youtube.com/channel/${sanitize(youtube)}`);
    }
  }
  if (!openGraph.images && image)
    openGraph.images = [
      {
        url: image,
        width: 1200,
        height: 630,
        alt: title,
      },
    ];
  if (!openGraph.images)
    openGraph.images = [
      {
        url: '/img/og.png',
        width: 828,
        height: 315,
        alt: 'TravelFeed: Travel, Write, Earn',
      },
    ];
  if (noImage) openGraph.images = undefined;
  return (
    <>
      <NextSeo
        title={sanitize(title)}
        description={sanitize(description)}
        canonical={
          // For stories: Use amp canonical for both amp & non amp version
          post.truvvl && post.truvvl.length > 0
            ? `https://travelfeed.com/amp/${author.username}/${post.permlink}`
            : // For AMP version: Use main post as canonical
            isAmp
            ? `https://travelfeed.com/@${author.username}/${post.permlink}`
            : sanitize(canonicalUrl)
        }
        openGraph={openGraph}
        twitter={{
          site: '@joinTravelFeed',
          cardType: 'summary_large_image',
        }}
        noindex={
          noIndex ||
          isIndex === false ||
          ROOTURL !== 'https://travelfeed.com' ||
          isNotFree
        }
        nofollow={noFollow}
        facebook={{
          appId: FACEBOOK_APP_ID,
        }}
      />
      {post.author && (
        <ArticleJsonLd
          type="BlogPosting"
          url={sanitize(canonicalUrl)}
          title={sanitize(title)}
          images={
            thumbnail
              ? [
                  imageCrop(thumbnail, 1920, 1920),
                  imageCrop(thumbnail, 1920, 1440),
                  imageCrop(thumbnail, 1920, 1080),
                ]
              : [
                  imageCrop(
                    author.avatar ||
                      'https://images.hive.blog/p/7ohP4GDMGPrVF5MeU8t5EQqCvJfGAJHyAFuxrYFhqA4BPKCkPjVBef1jSt7fHRrXVXRuRKBksi1FSJnZL8Co9zi6CpbK1bmV2sFR',
                    1920,
                    1920,
                  ),
                  imageCrop(
                    author.avatar ||
                      'https://images.hive.blog/p/7ohP4GDMGPrVF5MeU8t5EQqCvJfGAJHyAFuxrYFhqA4BPKCkPjVBef1jSt7fHRrXVXRuRKBksi1FSJnZL8Co9zi6CpbK1bmV2sFR',
                    1920,
                    1440,
                  ),
                  imageCrop(
                    author.avatar ||
                      'https://images.hive.blog/p/7ohP4GDMGPrVF5MeU8t5EQqCvJfGAJHyAFuxrYFhqA4BPKCkPjVBef1jSt7fHRrXVXRuRKBksi1FSJnZL8Co9zi6CpbK1bmV2sFR',
                    1920,
                    1080,
                  ),
                ]
          }
          datePublished={createdAt}
          dateModified={updatedAt}
          authorName={{
            name: sanitize(author.displayName),
            url: `https://travelfeed.com/@${author.username}`,
          }}
          publisherName="TravelFeed"
          publisherLogo="https://img.travelfeed.io/favicon.svg"
          description={sanitize(description)}
          isAccessibleForFree={!isNotFree}
        />
      )}
      {profile.author && (
        <SocialProfileJsonLd
          type="Person"
          name={sanitize(author.displayName)}
          url={`https://travelfeed.com/@${author.username}`}
          sameAs={socialProfiles}
        />
      )}
      {breadcrumbs && breadcrumbs.length > 0 && (
        <BreadcrumbJsonLd itemListElements={breadcrumbs} />
      )}
      {faq && faq.length > 0 && <FAQPageJsonLd mainEntity={faq} />}
      <NextHead>
        {feedUrl && (
          <link
            rel="alternate"
            type="application/rss+xml"
            title="TravelFeed RSS Feed"
            href={`/rss/${feedUrl}`}
          />
        )}
        {language && <meta httpEquiv="content-language" content={language} />}
        {!isAmp && (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
          />
        )}
        {scripts}
        {includeMapbox && (
          <link
            href="https://api.mapbox.com/mapbox-gl-js/v2.15.0/mapbox-gl.css"
            rel="stylesheet"
          />
        )}
        {seo.twitter &&
          (seo.twitter.title ||
            seo.twitter.description ||
            seo.twitter.thumbnail) && (
            <>
              {seo.twitter.title && (
                <meta
                  name="twitter:title"
                  content={sanitize(seo.twitter.title)}
                />
              )}
              {seo.twitter.description && (
                <meta
                  name="twitter:description"
                  content={sanitize(seo.twitter.description)}
                />
              )}
              {seo.twitter.thumbnail && (
                <meta
                  name="twitter:image"
                  content={sanitize(seo.twitter.thumbnail)}
                />
              )}
            </>
          )}
      </NextHead>
    </>
  );
};
// todo: if  canonical undefined no canonical!
Head.defaultProps = {
  description:
    'Travel, Write, Earn: Start earning with your free travel blog and discover the best travel content on TravelFeed, the world-wide travel community!',
  post: {},
  profile: {},
};

Head.propTypes = {
  description: PropTypes.string,
  post: PropTypes.object,
  profile: PropTypes.object,
};

export default Head;
