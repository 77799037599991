import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import NewsletterSubscribe from '../Sidebar/NewsLetterSubscribe';
import Image from 'components/FutureImage';

const useStyles = makeStyles()(() => ({
  heading: {
    textShadow: '1px 1px 20px #343A40',
  },
}));

export default function NewsletterSubscription() {
  const { classes } = useStyles();

  return (
    <div className="tw-relative tw-full tw-h-[550px] tw-flex">
      <Image
        src="https://img.travelfeed.io/bg-002.jpg"
        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAIAAAB2XpiaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAFUlEQVR4nGOQZmC4unp2hJ78x7OHARsFBV3nj1R+AAAAAElFTkSuQmCC"
        priority
        alt="Travel Blog"
        placeholder="blur"
        sizes="100vw"
        className="tw-object-cover tw-object-center"
        fill
      />
      <div className="my-auto container text-center tw-z-10">
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          className={`font-weight-bold text-light ${classes.heading} pb-4`}
        >
          Receive the best travel blogs in your inbox
        </Typography>
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-9 col-sm-10 col-12">
            <NewsletterSubscribe isOnlyForm />
          </div>
        </div>
      </div>
    </div>
  );
}
