import { faBitcoin } from '@fortawesome/free-brands-svg-icons';
import {
  faBolt,
  faBroom,
  faGlobeEurope,
  faLock,
  faMobile,
  faPalette,
  faPlayCircle,
  faRoute,
  faTable,
  faUserFriends,
  faBrain,
} from '@fortawesome/free-solid-svg-icons';

const highlights = [
  {
    title: 'Start for free',
    description: 'Blogging on TravelFeed is free - no credit card required',
    icon: faPlayCircle,
  },
  {
    title: 'Use your own domain',
    description: 'Use your own domain in our optional site builder plans',
    icon: faGlobeEurope,
  },
  {
    title: 'Avoid cold start',
    description: 'Find your audience in the TravelFeed community',
    icon: faUserFriends,
  },
  {
    title: 'Optimized for travelers',
    description:
      'How does custom maps, automatic categorization by destination and destination search sound?',
    icon: faRoute,
  },
  {
    title: 'Craft beautiful posts',
    description:
      'Take advantage of powerful editor blocks such as maps, photo galleries, Instagram embeds and more',
    icon: faTable,
  },
  {
    title: 'Powerful AI tools',
    description:
      'Our AI Blogger helps you create high-quality travel blog content in minutes with the power of artificial intelligence.',
    icon: faBrain,
  },
  {
    title: 'Fast by default',
    description:
      'TravelFeed uses edge caching to serve your articles super fast to your readers in every corner of the world',
    icon: faBolt,
  },
  {
    title: 'Add your personality',
    description:
      'Your blog is fully customizable with our site-builder - no coding skills required',
    icon: faPalette,
  },
  {
    title: 'HTTPS included',
    description: 'We automatically provide free SSL certificates for your site',
    icon: faLock,
  },
  {
    title: 'Earn crypto',
    description:
      'Cross-post to Hive and earn crypto. Some bloggers have earned thousands of dollars!',
    icon: faBitcoin,
  },
  {
    title: 'Maintenance-free',
    description: 'Never waste time updating plugins, themes or the database',
    icon: faBroom,
  },
  {
    title: 'Create posts on the go',
    description:
      'Use our mobile app to create travel stories on the go and post them to your blog',
    icon: faMobile,
  },
];

export default highlights;
