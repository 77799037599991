import { gql } from '@apollo/client';

export const GET_POSTS = gql`
  query posts(
    $countryCode: [String]
    $subdivision: String
    $city: String
    $locationBox: [Float]
    $author: String
    $tag: String
    $community: Int
    $language: String
    $orderby: String
    $offset: Int
    $limit: Int
    $useBlacklist: Boolean
    $minScore: Int
    $onlyBookmarks: Boolean
    $onlyRunningContests: Boolean
    $onlyVideo: Boolean
    $onlyTruvvl: Boolean
    $following: [String]
    $isBlog: Boolean
  ) {
    posts(
      countryCode: $countryCode
      subdivision: $subdivision
      city: $city
      locationBox: $locationBox
      author: $author
      tag: $tag
      community: $community
      language: $language
      orderby: $orderby
      offset: $offset
      limit: $limit
      useBlacklist: $useBlacklist
      minScore: $minScore
      onlyBookmarks: $onlyBookmarks
      onlyRunningContests: $onlyRunningContests
      onlyVideo: $onlyVideo
      onlyTruvvl: $onlyTruvvl
      following: $following
      isBlog: $isBlog
    ) {
      createdAt
      author {
        username
        displayName
        avatar
        roles
      }
      excerpt
      permlink
      title
      location {
        countryCode
        subdivision
        city
      }
      comments
      totalSmiles
      thumbnail
      thumb {
        url
        blur
      }
      youtubeId
      wordCount
      readTime
      smiles {
        voter
        value
      }
      community {
        id
        title
        lang
      }
    }
  }
`;

export const GET_MINIMAL_POSTS = gql`
  query posts(
    $countryCode: [String]
    $language: String
    $orderby: String
    $limit: Int
    $useBlacklist: Boolean
    $minScore: Int
    $onlyRunningContests: Boolean
  ) {
    posts(
      countryCode: $countryCode
      language: $language
      orderby: $orderby
      limit: $limit
      useBlacklist: $useBlacklist
      minScore: $minScore
      onlyRunningContests: $onlyRunningContests
    ) {
      createdAt
      author {
        username
        displayName
      }
      permlink
      title
      thumb {
        url
        blur
      }
      location {
        countryCode
      }
    }
  }
`;

export const GET_CHAIN_POSTS = gql`
  query chainPosts($chainStats: String) {
    chainPosts(chainStats: $chainStats) {
      createdAt
      author {
        username
        displayName
        avatar
        roles
      }
      location {
        countryCode
        subdivision
        city
      }
      community {
        id
        title
        lang
      }
      body
      permlink
      createdAt
      wordCount
      tags
      title
      thumbnail
      readTime
      steemAuthor
      steemPerm
      hiveAuthor
      hivePerm
      evaluation {
        score
      }
      youtubeId
    }
  }
`;

export const GET_RSS_POSTS = gql`
  query posts(
    $countryCode: [String]
    $subdivision: String
    $city: String
    $author: String
    $tag: String
    $community: Int
    $language: String
    $orderby: String
    $limit: Int
    $useBlacklist: Boolean
    $minScore: Int
  ) {
    posts(
      countryCode: $countryCode
      subdivision: $subdivision
      city: $city
      author: $author
      tag: $tag
      community: $community
      language: $language
      orderby: $orderby
      limit: $limit
      useBlacklist: $useBlacklist
      minScore: $minScore
    ) {
      createdAt
      author {
        username
        displayName
      }
      excerpt
      permlink
      title
      thumbnail
      location {
        coordinates {
          coordinates
        }
      }
      community {
        lang
      }
    }
  }
`;

export const GET_CURATION_POSTS = gql`
  query curationPosts {
    curationPosts {
      tags
      createdAt
      author {
        username
        displayName
        avatar
      }
      body
      permlink
      title
      location {
        countryCode
        subdivision
        city
      }
      thumbnail
      youtubeId
      wordCount
      readTime
      community {
        id
        title
        lang
      }
      evaluation {
        notes
        attentionLevel
        formatting
        language
        bilingual
        footer
        photos
        short
        writing
        valueadding
      }
      _id
    }
  }
`;

export const GET_DISCOVER_POSTS = gql`
  query posts(
    $countryCode: [String]
    $minScore: Int
    $limit: Int
    $orderby: String
  ) {
    posts(
      countryCode: $countryCode
      minScore: $minScore
      limit: $limit
      orderby: $orderby
      language: "en"
    ) {
      author {
        username
        avatar
      }
      permlink
      title
      thumb {
        url
        blur
      }
      youtubeId
    }
  }
`;

export const GET_RECOMMENDED_POSTS = gql`
  query posts(
    $countryCode: [String]
    $orderby: String
    $minScore: Int
    $limit: Int
    $nearCoordinates: [Float]
    $language: String
    $community: Int
  ) {
    posts(
      countryCode: $countryCode
      orderby: $orderby
      minScore: $minScore
      limit: $limit
      nearCoordinates: $nearCoordinates
      language: $language
      community: $community
    ) {
      author {
        username
        displayName
      }
      permlink
      title
      thumb {
        url
        blur
      }
      location {
        countryCode
        subdivision
        city
      }
      youtubeId
    }
  }
`;

export const GET_BLOG_POSTS = gql`
  query posts(
    $author: String
    $limit: Int
    $tag: String
    $useBlacklist: Boolean
  ) {
    posts(
      author: $author
      limit: $limit
      tag: $tag
      orderby: "createdAt"
      useBlacklist: $useBlacklist
    ) {
      author {
        username
      }
      permlink
      title
    }
  }
`;

export const GET_PRIVATE_POSTS = gql`
  query privatePosts($limit: Int, $offset: Int) {
    privatePosts(limit: $limit, offset: $offset) {
      author {
        username
      }
      evaluation {
        formatting
        language
        bilingual
        footer
        photos
        short
        writing
        valueadding
        score
      }
      blacklist {
        isBlacklistedFromFeed
        isBlacklistedFromPost
        isUnlisted
      }
      excerpt
      permlink
      title
      updatedAt
      location {
        countryCode
        subdivision
        city
      }
      steemAuthor
      steemPerm
      hiveAuthor
      hivePerm
      thumbnail
      comments
      totalSmiles
      views
    }
  }
`;

export const GET_DASHBOARD_POSTS = gql`
  query privatePosts($limit: Int) {
    privatePosts(limit: $limit) {
      author {
        username
      }
      permlink
      title
      createdAt
      totalSmiles
      views
    }
  }
`;

export const GET_AUTHOR_POST_LOCATIONS = gql`
  query posts($author: String) {
    posts(author: $author, hasLocation: true) {
      _id
      author {
        username
      }
      permlink
      title
      location {
        countryCode
        coordinates {
          coordinates
        }
      }
      thumb {
        url
        blur
      }
      youtubeId
    }
  }
`;

export const DELETE_POST = gql`
  mutation deletePost($permlink: String!) {
    deletePost(permlink: $permlink) {
      success
      message
    }
  }
`;

export const UNLIST_POST = gql`
  mutation unlistPost($permlink: String!) {
    unlistPost(permlink: $permlink) {
      success
      message
    }
  }
`;

export const RELIST_POST = gql`
  mutation relistPost($permlink: String!) {
    relistPost(permlink: $permlink) {
      success
      message
    }
  }
`;
