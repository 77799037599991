import Typography from '@mui/material/Typography';
import BadgeLandingHightlight from './BadgeLandingHightlight';
import emoji from '/components/emoji';
import highlights from 'helpers/highlights';

export default function BadgeLanding({ title }) {
  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        className="text-center font-weight-bold mb-3 pb-2"
      >
        {title ? title : <>{emoji('😍')} Key highlights of TravelFeed</>}
      </Typography>
      <div className="container-fluid">
        <div className="row">
          {highlights.map(highlight => {
            return (
              <BadgeLandingHightlight {...highlight} key={highlight.title} />
            );
          })}
        </div>
      </div>
    </>
  );
}
