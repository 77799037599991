import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Skeleton from '@mui/material/Skeleton';
import LazyLoad from 'react-lazyload';

export default function ChannelIntroVideo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const height = isMobile ? 350 : 550;

  const placeholder = (
    <Skeleton variant="rectangular" width="100%" height={height} />
  );

  return (
    <div className="container pt-3">
      <LazyLoad height={height} placeholder={placeholder}>
        <iframe
          title="Cover Video"
          className="embed-resonsive w-100"
          height={height}
          src="https://www.youtube-nocookie.com/embed/1Evcqh3zlC0?autoplay=1&mute=1&modestbranding=1&rel=0"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </LazyLoad>
    </div>
  );
}
