/* eslint-disable import/no-unused-modules */
import { useQuery } from '@apollo/client';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import LazyLoad from 'react-lazyload';
import { GET_POSTS } from '../../helpers/graphql/posts';
import aboutImg from '../../images/about.svg';
import appImg from '../../images/app.svg';
import blogImg from '../../images/blog.svg';
import domainImg from '../../images/domain.svg';
// import contestImg from '../../images/contest.svg';
import earnImg from '../../images/earn.svg';
import aiImg from '../../images/aiPost.svg';
import earnHiveImg from '../../images/earnHive.svg';
import integrationsImg from '../../images/integrations.svg';
import mapImg from '../../images/map.svg';
import videoImg from '../../images/video.svg';
import Footer from '../General/Footer';
import BadgeJoinButton from '../Profile/BadgeJoinButton';
import BadgeLanding from '../Profile/BadgeLanding';
import ChannelIntroVideo from './ChannelIntroVideo';
import NewsletterSubscription from './NewsletterSubscription';
import SearchJumbotron from './SearchJumbotron';

const TopicCarousel = dynamic(() => import('./TopicCarousel'), {
  loading: () => <div style={{ height: '337px' }} />,
  ssr: false,
});

export default function FrontPage({
  topics,
  featuredCountries,
  featuredPlaces,
}) {
  const [webpSupport, setWebpSupport] = useState(undefined);
  const [hive, setHive] = useState(false);

  useEffect(() => {
    if (webpSupport === undefined) setWebpSupport(isWebpSupported());
    if (window && window.hive_keychain) {
      setHive(true);
    }
  });

  const topCarouselItems = {
    posts: [
      {
        heading: 'Start',
        subheading: 'a travel blog',
        image: { src: blogImg, width: 1009.54, height: 717.96 },
        bgColor: '#29c7ac',
        isJoinPopup: true,
      },
      {
        heading: 'Use',
        subheading: 'your own domain',
        permlink: '/join',
        image: { src: domainImg, width: 951.84, height: 610.17 },
        bgColor: '#00adb5',
      },
      // {
      //   heading: 'Participate',
      //   subheading: 'in our contests',
      //   permlink: '/contests',
      //   image: contestImg,
      //   bgColor: '#f7b500',
      // },

      {
        heading: 'Explore',
        subheading: 'the world map',
        permlink: '/map',
        image: { src: mapImg, width: 888, height: 637.96158 },
        bgColor: '#278ea5',
      },
      {
        heading: 'Earn',
        subheading: `from blogging`,
        permlink: '/how-to-make-money-with-a-travel-blog',
        image: hive
          ? { src: earnHiveImg, width: 682, height: 729.04 }
          : { src: earnImg, width: 682, height: 729.04 },
        bgColor: '#00adb5',
      },
      {
        heading: 'Get',
        subheading: 'our app',
        permlink: '/app',
        image: { src: appImg, width: 865.80173, height: 650.4628 },
        bgColor: '#115173',
      },
      {
        heading: 'Watch',
        subheading: 'travel videos',
        permlink: '/videos',
        image: { src: videoImg, width: 1150.44, height: 675.56 },
        bgColor: '#00adb5',
      },
      {
        heading: 'Try',
        subheading: 'our AI Blogger',
        permlink: '/ai',
        image: aiImg,
        bgColor: '#ff6363',
      },
      // {
      //   heading: 'Become',
      //   subheading: 'an ambassador',
      //   permlink: '/about/contact',
      //   image: ambassadorImg,
      //   bgColor: '#278ea5',
      // },
      {
        heading: 'Connect',
        subheading: 'your favorite apps',
        permlink: '/help/category/integrations',
        image: { src: integrationsImg, width: 965.82539, height: 729.2137 },
        bgColor: '#278ea5',
      },
      {
        heading: 'Learn more',
        subheading: 'about us',
        permlink: '/about',
        image: { src: aboutImg, width: 1021.5, height: 782.9541 },
        bgColor: '#91b315',
      },
    ],
  };

  const queryRecentlyFeatured = useQuery(GET_POSTS, {
    variables: {
      limit: 12,
      language: 'en',
      orderby: 'createdAt',
      minScore: 14000,
    },
  });
  let recentlyFeatured;
  if (queryRecentlyFeatured && queryRecentlyFeatured.data)
    recentlyFeatured = {
      title: 'Recently Featured',
      emoji: '⭐',
      ...queryRecentlyFeatured.data,
    };

  const topics1 = [];
  const topics2 = [];
  const topics3 = [];
  topics.forEach((topic, i) => {
    if (i < 2) topics1.push(topic);
    else if (i < 4) topics2.push(topic);
    else topics3.push(topic);
  });

  const placeholder = <div style={{ height: 1200 }} />;

  return (
    <>
      <SearchJumbotron />
      <div className="container">
        <TopicCarousel
          isCardItem
          carouselTopic={topCarouselItems}
          webpSupport={webpSupport}
        />
      </div>
      <div className="tw-bg-primary-100 dark:tw-bg-primary-900 tw-relative tw-py-4 tw-mt-4 tw-mb-8">
        <div className="container containerInvisibleOnMobile mt-5 mb-4">
          <BadgeLanding />
          <BadgeJoinButton caption="Get started for free" />
        </div>
        <div className="tw-absolute tw-top-0 tw-z-10 -tw-mb-12 tw-w-full sm:-tw-mb-16">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
            className="tw-h-12 tw-w-full sm:tw-h-16"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="tw-fill-[#F3F4F7] dark:tw-fill-[#232A2E]"
            ></path>
          </svg>
        </div>
        <div className="tw-absolute tw-bottom-0 tw-z-10 -tw-mb-12 tw-w-full sm:-tw-mb-16">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
            className="tw-h-12 tw-w-full sm:tw-h-16"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="tw-fill-primary-100 dark:tw-fill-primary-900"
            ></path>
          </svg>
        </div>
      </div>
      <LazyLoad offset={500} height={1200} once placeholder={placeholder}>
        <div className="container-fluid pt-3">
          <TopicCarousel
            webpSupport={webpSupport}
            isCountryItem
            carouselTopic={{
              title: 'Where will you travel next?',
              posts: featuredCountries,
              emoji: '🧳',
            }}
          />
        </div>
        {recentlyFeatured && recentlyFeatured && (
          <div className="container pt-3">
            <TopicCarousel
              carouselTopic={recentlyFeatured}
              webpSupport={webpSupport}
              moreHref="/featured"
            />
          </div>
        )}
        <NewsletterSubscription />
        <div className="container pt-3">
          {topics1.map(topic => {
            return (
              <TopicCarousel
                key={topic.title}
                carouselTopic={topic}
                webpSupport={webpSupport}
              />
            );
          })}
        </div>
      </LazyLoad>
      <LazyLoad offset={500} height={1200} once placeholder={placeholder}>
        <div className="container-fluid pt-3">
          <TopicCarousel
            isCountryItem
            carouselTopic={{
              title: `The Communities' Favorite Destinations`,
              posts: featuredPlaces,
              emoji: '🤗',
            }}
            webpSupport={webpSupport}
          />
        </div>
        <div className="container pt-3">
          {topics2.map(topic => {
            return (
              <TopicCarousel
                key={topic.title}
                carouselTopic={topic}
                webpSupport={webpSupport}
              />
            );
          })}
        </div>
        <ChannelIntroVideo />
        <div className="container pt-3">
          {topics3.map(topic => {
            return (
              <TopicCarousel
                key={topic.title}
                carouselTopic={topic}
                webpSupport={webpSupport}
              />
            );
          })}
        </div>
        <Footer />
      </LazyLoad>
    </>
  );
}
