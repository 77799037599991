import { Mutation } from '@apollo/client/react/components';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import isEmail from 'validator/lib/isEmail';
import { RECAPTCHA_SITE_KEY } from '../../config';
import { NEWSLETTER_SUBSCRIBE } from '../../helpers/graphql/newsletter';
import CustomSnackbar from '../Dashboard/Notifications/CustomSnackbar';
import HeaderCard from '../General/HeaderCard';

const useStyles = makeStyles(theme => ({
  inputMain: () => ({
    borderRadius: theme.shape.borderRadius,
    height: '45px',
    backgroundColor: alpha(theme.palette.common.white, 0.65),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.75),
    },
    color: 'black',
    padding: '5px',
    transition: 'width 0.3s',
  }),
}));

const NewsLetterSubscribe = ({ isOnlyForm, isRoundBtn }) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [isMailValid, setMailValid] = useState(true);
  const [captcha, setCaptcha] = useState(undefined);

  const handleEmailChange = () => event => {
    setEmail(event.target.value);
    if (event.target.value) setMailValid(isEmail(event.target.value));
  };

  const formContent = (
    <Mutation
      mutation={NEWSLETTER_SUBSCRIBE}
      variables={{
        email,
        captcha,
      }}
    >
      {(
        newsletterSubscribe,
        // eslint-disable-next-line no-shadow
        { data, loading },
      ) => {
        if (loading) {
          return <CircularProgress />;
        }
        if (data && data.newsletterSubscribe) {
          return (
            <CustomSnackbar
              variant={data.newsletterSubscribe.success ? 'success' : 'error'}
              message={
                data.newsletterSubscribe.success
                  ? 'We just sent you an email.'
                  : data.newsletterSubscribe.message
              }
            />
          );
        }
        return (
          <>
            <div>
              <FormControl
                variant="standard"
                fullWidth
                required
                error={!isMailValid}
              >
                <TextField
                  inputProps={{
                    placeholder: 'Your Email',
                    className: isOnlyForm ? classes.inputMain : undefined,
                  }}
                  variant={isOnlyForm ? 'filled' : 'outlined'}
                  label="Your Email"
                  value={email}
                  onChange={handleEmailChange()}
                  className={
                    isOnlyForm
                      ? 'tw-bg-white dark:tw-bg-gray-900 tw-rounded'
                      : undefined
                  }
                />
                {!isMailValid && (
                  <FormHelperText>A valid email is required</FormHelperText>
                )}
              </FormControl>
            </div>
            {email && (
              <div className="pt-3 text-center">
                <ReCAPTCHA
                  size="compact"
                  style={{ display: 'inline-block' }}
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={token => setCaptcha(token)}
                />
              </div>
            )}
            <div className="pt-3">
              {isRoundBtn ? (
                <button
                  className="form-control-submit-button"
                  onClick={newsletterSubscribe}
                  disabled={!captcha || !email || !isMailValid}
                >
                  SUBSCRIBE
                </button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={newsletterSubscribe}
                  disabled={!captcha || !email || !isMailValid}
                >
                  Get the newsletter
                </Button>
              )}
            </div>
          </>
        );
      }}
    </Mutation>
  );

  if (isOnlyForm) return formContent;

  return (
    <HeaderCard
      title="Receive the best travel content in your inbox"
      titlesize="h6"
      bgCard
      content={<>{formContent}</>}
    />
  );
};

export default NewsLetterSubscribe;
