import { asyncForEach } from 'tf-post-parser';
import Head from '../components/Header/Head';
import Header from '../components/Header/Header';
import { GET_FEATURED_LOCATIONS } from '../helpers/graphql/locations';
import { GET_MINIMAL_POSTS } from '../helpers/graphql/posts';
import ssrGraphQLClient from '../helpers/ssrGraphQLClient';
import withApollo from '../lib/withApollo';
import FrontPage from 'components/FrontPage/FrontPage';

const shuffle = a => {
  // eslint-disable-next-line no-plusplus
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line no-param-reassign
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const FeaturedFeed = props => {
  return (
    <>
      <Head
        justTitle="TravelFeed: Travel Blogging for Everyone"
        feedUrl="featured"
        description="TravelFeed makes travel blogging easy and fun. Explore travel stories, insider experiences and recommendations created by our large community of travelers. Start your own travel blog and earn crypto."
        seo={{ canonicalUrl: 'https://travelfeed.com' }}
      />
      <Header isScrollingNav />
      <FrontPage {...props} />
    </>
  );
};

export default withApollo(FeaturedFeed);

export async function getStaticProps() {
  const { featuredLocations } = await ssrGraphQLClient(GET_FEATURED_LOCATIONS, {
    countries: true,
  });
  const featuredLocationsPlaces = await ssrGraphQLClient(
    GET_FEATURED_LOCATIONS,
    {
      places: true,
    },
  );
  const featuredPlaces = shuffle(
    featuredLocationsPlaces.featuredLocations,
  ).slice(0, 12);
  const chosenLocations = shuffle(featuredLocations);
  const carouselLocations = chosenLocations.slice(0, 6);
  const featuredCountries = chosenLocations.slice(6, 18);
  const topics = [];
  await asyncForEach(carouselLocations, async location => {
    const { title, countryCode } = location;
    await ssrGraphQLClient(GET_MINIMAL_POSTS, {
      countryCode,
      limit: 12,
      language: 'en',
      orderby: 'score',
    }).then(({ posts }) => {
      if (posts) topics.push({ title, countryCode, posts });
    });
  });
  return {
    props: { topics, featuredCountries, featuredPlaces },
  };
}
