import urlSafeBase64 from 'helpers/urlSafeBase64';
const bs58 = require('bs58');

const imageCrop = (src, width, height) => {
  let imgUrl = src;

  // Detect (animated) gif, proxy through hiveimages
  const isGif = imgUrl.substring(imgUrl.length - 3, imgUrl.length) === 'gif';
  if (isGif) {
    if (imgUrl.substring(0, 25) === 'https://images.hive.blog/') return imgUrl;
    return 'https://images.hive.blog/0x0/' + imgUrl;
  }
  if (
    imgUrl.match(
      /(https:\/\/ipfs-3speak\.b-cdn\.net\/ipfs\/)https:\/\/ipfs-3speak\.b-cdn\.net\/ipfs\/(?:.*)/,
    )
  ) {
    imgUrl = imgUrl.replace(/^https:\/\/ipfs-3speak\.b-cdn\.net\/ipfs\//, '');
  }
  if (
    !imgUrl.match(/img\.travelfeed\.io/) &&
    !imgUrl.match(/steemitimages\.com/) &&
    !imgUrl.match(/files\.steempeak\.com/) &&
    !imgUrl.match(/files\.peakd\.com/) &&
    !imgUrl.match(/images\.hive\.blog/) &&
    !imgUrl.match(/images\.unsplash\.com/) &&
    !imgUrl.match(/img\.esteem\.app/) &&
    !imgUrl.match(/images\.ecency\.com/) &&
    !imgUrl.match(/ipfs-3speak\.b-cdn\.net/) &&
    !imgUrl.match(/img\.3speakcontent\.co/)
  ) {
    // Steemitimages needs to be used because it has a cache of images deleted from the origin
    // Base58 encode image url
    // https://github.com/steemit/imagehoster
    const bytes = Buffer.from(imgUrl);
    const address = bs58.encode(bytes);
    // Use webp as format for best compression if supported
    // Get the cropped steemitimages URL for an image
    imgUrl = `https://steemitimages.com/p/${address}/?format=match&mode=fit`;
  }
  if (imgUrl.match(/img\.3speakcontent\.co/)) {
    const bytes = Buffer.from(imgUrl);
    const address = bs58.encode(bytes);
    imgUrl = `https://images.hive.blog/p/${address}/?format=match&mode=fit`;
    console.log(imgUrl);
  }

  const encodedURL = urlSafeBase64(imgUrl);

  return (
    'https://img.truvvle.com/?src=' +
    encodedURL +
    '&width=' +
    width +
    (height ? '&height=' + height : '')
  );
};

module.exports = imageCrop;
