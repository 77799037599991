import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import shadow from '../../helpers/shadow';

const HeaderCard = props => {
  const useStyles = makeStyles()(theme => ({
    root: {
      backgroundColor: theme.palette.background.light,
    },
    header: {
      backgroundColor: props.background || theme.palette.background.light,
    },
    rounded: {
      borderRadius: 16,
      boxShadow: shadow.lg,
    },
    newUserCard: {
      backgroundColor: theme.palette.background.paper,
    },
  }));

  const { classes } = useStyles();

  return (
    <Card
      className={
        props.newUserCard
          ? props.isRounded
            ? classes.newUserCard + ' ' + classes.rounded
            : classes.newUserCard
          : props.bgCard
          ? classes.root
          : classes.rounded
      }
      style={
        props.hideTopBorder
          ? {
              border: 'none',
              boxShadow: 'none',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }
          : undefined
      }
    >
      <CardHeader
        className={props.newUserCard ? classes.newUserCard : classes.header}
        title={
          <Typography
            color="textPrimary"
            variant={props.titlesize}
            align={props.bgCard ? undefined : 'center'}
            className={
              'tw-font-semibold ' +
              (props.bgCard
                ? 'p-0'
                : 'p-2' + (props.background ? ' tw-text-gray-100' : ''))
            }
          >
            {props.title}
          </Typography>
        }
      />
      {props.noborder && props.content}
      {!props.noborder && (
        <CardContent className={props.nobottomborder ? 'pb-0' : undefined}>
          {props.content}
        </CardContent>
      )}
    </Card>
  );
};

HeaderCard.defaultProps = {
  titlesize: 'h4',
  noborder: false,
};

HeaderCard.propTypes = {
  titlesize: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  noborder: PropTypes.bool,
};

export default HeaderCard;
