import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@mui/material/Typography';

export default function BadgeLandingHightlight({ title, description, icon }) {
  return (
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 pb-3">
      <div className="d-flex">
        <div className="pt-1">
          {icon && (
            <Typography color="textSecondary">
              <FontAwesomeIcon
                style={{ width: '45px', height: '45px' }}
                icon={icon}
              />
            </Typography>
          )}
        </div>
        <div className="pl-3">
          <Typography variant="h6" component="h3" color="textPrimary">
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
        </div>
      </div>
    </div>
  );
}
