import EasyEmoji from 'react-easy-emoji';

export default function emoji(input) {
  return EasyEmoji(input, {
    baseUrl: '/img/emoji/svg',
    ext: '.svg',
    size: '',
    protocol: '',
  });
}
