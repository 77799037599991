import Button from '@mui/material/Button';
import { useState } from 'react';
import LoginButton from '../Header/LoginButton';

export default function BadgeJoinButton({ caption }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="text-center mt-4">
      <Button onClick={() => setOpen(true)} variant="contained" color="primary">
        {caption}
      </Button>
      {open && (
        <LoginButton
          open={open}
          hideButtons
          onClickClose={() => setOpen(false)}
          text=" and start your own travel blog"
        />
      )}
    </div>
  );
}
